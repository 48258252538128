// Import map components
import sitemapComponent from "@/components/Maps/SiteMap.vue";
import satelliteMapComponent from "@/components/Maps/SatelliteMap.vue";
import amenitiesMapComponent from "@/components/Maps/AmenitiesMap.vue";
import busServicesMapComponent from "@/components/Maps/BusServicesMap.vue";
import railStationsMapComponent from "@/components/Maps/RailStationsMap.vue";
import pedestrianMapComponent from "@/components/Maps/PedestrianIsochroneMap.vue";
import cyclingMapComponent from "@/components/Maps/CyclingIsochroneMap.vue";
import drivingMapComponent from "@/components/Maps/DrivingIsochroneMap.vue";
import highwaysMapComponent from "@/components/Maps/HighwaysMap.vue";
import collisionMapComponent from "@/components/Maps/CollisionMap.vue";

import uk_amenities_map_config from "@/config/amenities_maps/uk_amenities_map.json";
import us_amenities_map_config from "@/config/amenities_maps/us_amenities_map.json";
import osm_bus_map_config from "@/config/amenities_maps/osm_bus_map.json";
import local_transport_map_config from "@/config/amenities_maps/local_transport_map.json";
import ev_charger_map_config from "@/config/amenities_maps/ev_charger_map.json";
import community_facilities_map_config from "@/config/amenities_maps/community_facilities_map.json";
import ea_reservoir_flood_extents_map_config from "@/config/amenities_maps/ea_reservoir_flood_extents_map.json";
import ea_flood_risk_zones_map_config from "@/config/amenities_maps/ea_flood_risk_zones_map.json";
import ea_main_rivers_map_config from "@/config/amenities_maps/ea_main_rivers_map.json";
import ea_surface_water_flood_risk_extents_map_config from "@/config/amenities_maps/ea_surface_water_flood_risk_extents_map.json";

// Import icons
import SiteLocationMapIcon from "@/assets/icons/site_location_map.svg?url";
import SatelliteMapIcon from "@/assets/icons/satellite_map.svg?url";
import AmenitiesMapIcon from "@/assets/icons/amenities_map.svg?url";
import BusServicesIcon from "@/assets/icons/bus_services.svg?url";
import WalkingIsochroneIcon from "@/assets/icons/walking_isochrone.svg?url";
import CyclingIsochroneIcon from "@/assets/icons/cycling_isochrone.svg?url";
import DrivingIsochroneIcon from "@/assets/icons/driving_isochrone.svg?url";
import HighwaysMapIcon from "@/assets/icons/highways_map.svg?url";
import RailServicesIcon from "@/assets/icons/rail_services.svg?url";
import PolicyIcon from "@/assets/icons/policy.svg?url";
import ParkingStandardsIcon from "@/assets/icons/parking_standards.svg?url";
import CollisionMapIcon from "@/assets/icons/collisions_map.svg?url";
import ChargerIcon from "@/assets/icons/charger.svg?url";
import CommunityFacilitiesMapIcon from "@/assets/icons/groups_2.svg?url";
import FloodRiskMapIcon from "@/assets/icons/flood_risk.svg?url";

// External module icons
import TflWebcatPTALIcon from "@/assets/icons/externalTools/tfl_webcat_ptal.svg?url";
import NationalHighwaysCountsIcon from "@/assets/icons/externalTools/national_highways_counts.svg?url";
import NationalCycleNetworkIcon from "@/assets/icons/externalTools/national_cycle_network.svg?url";
import BikeShareSchemesIcon from "@/assets/icons/externalTools/bike_share_schemes.svg?url";
import EVChargingPointsIcon from "@/assets/icons/externalTools/ev_charging_points.svg?url";
import RailStationUsageIcon from "@/assets/icons/externalTools/rail_station_usage.svg?url";
import StreetviewIcon from "@/assets/icons/externalTools/streetview.svg?url";
import GoogleMapsIcon from "@/assets/icons/externalTools/googlemaps.svg?url";
import TrafficIcon from "@/assets/icons/externalTools/traffic.svg?url";
import TravelToWorkFlowsIcon from "@/assets/icons/externalTools/travel_to_work_flows.svg?url";
import TravelToWorkMethodsIcon from "@/assets/icons/externalTools/travel_to_work_mode_shares.svg?url";
import DistanceTravelledToWorkIcon from "@/assets/icons/externalTools/travel_to_work_distances.svg?url";
import CarOwnershipIcon from "@/assets/icons/externalTools/car_ownership.svg?url";
import CarClubIcon from "@/assets/icons/externalTools/car_club.svg?url";
import OffsiteParkingIcon from "@/assets/icons/externalTools/offsite_parking.svg?url";
import LightRailIcon from "@/assets/icons/externalTools/light_rail_usage.svg?url";
import TRICsIcon from "@/assets/icons/externalTools/trics.svg?url";

export const today = new Date();
export const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const usCityOptions = {
    California: [
        "Antioch",
        "Berkeley",
        "Cupertino",
        "Dublin",
        "Fairfield",
        "Fremont",
        "Hayward",
        "Milpitas",
        "Mountain View",
        "Napa",
        "Novato",
        "Oakland",
        "Palo Alto",
        "Petaluma",
        "Redwood City",
        "San Francisco",
        "San Jose",
        "San Leandro",
        "San Mateo",
        "San Rafael",
        "San Ramon",
        "Santa Clara",
        "Santa Rosa",
        "South San Francisco",
        "Sunnyvale",
        "Vallejo",
        "Walnut Creek",
    ],
};

export const localPlanningAuthorityOptions = [
    "Aberdeen City Council",
    "Aberdeenshire Council",
    "Adur District Council",
    "Allerdale Borough Council",
    "Amber Valley Borough Council",
    "Angus Council",
    "Antrim and Newtownabbey Borough Council",
    "Ards and North Down Borough Council",
    "Argyll and Bute Council",
    "Armagh City, Banbridge and Craigavon Borough Council",
    "Arun District Council",
    "Ashfield District Council",
    "Ashford Borough Council",
    "Babergh District Council",
    "Barnsley Metropolitan Borough Council",
    "Barrow-in-Furness Borough Council",
    "Basildon Borough Council",
    "Basingstoke and Deane Borough Council",
    "Bassetlaw District Council",
    "Bath and North East Somerset Council",
    "Bedford Borough Council",
    "Belfast City Council",
    "Birmingham City Council",
    "Blaby District Council",
    "Blackburn with Darwen Borough Council",
    "Blackpool Borough Council",
    "Blaenau Gwent County Borough Council",
    "Bolsover District Council",
    "Bolton Metropolitan Borough Council",
    "Borough of Broxbourne",
    "Boston Borough Council",
    "Bournemouth, Christchurch and Poole Council",
    "Bracknell Forest Council",
    "Bradford Metropolitan District Council",
    "Braintree District Council",
    "Breckland District Council",
    "Brentwood Borough Council",
    "Bridgend County Borough Council",
    "Brighton and Hove City Council",
    "Bristol City Council",
    "Broadland District Council",
    "Broads Authority",
    "Bromsgrove District Council",
    "Broxtowe Borough Council",
    "Buckinghamshire Council",
    "Burnley Borough Council",
    "Bury Metropolitan Borough Council",
    "Caerphilly County Borough Council",
    "Calderdale Metropolitan Borough Council",
    "Cambridge City Council",
    "Cannock Chase District Council",
    "Canterbury City Council",
    "Cardiff Council",
    "Carlisle City Council",
    "Carmarthenshire County Council",
    "Castle Point Borough Council",
    "Causeway Coast and Glens Borough Council",
    "Central Bedfordshire Council",
    "Ceredigion County Council",
    "Charnwood Borough Council",
    "Chelmsford City Council",
    "Cheltenham Borough Council",
    "Cherwell District Council",
    "Cheshire East Council",
    "Cheshire West and Chester Council",
    "Chesterfield Borough Council",
    "Chichester District Council",
    "Chorley Council",
    "City and County of Swansea Council",
    "City of Edinburgh Council",
    "City of Lincoln Council",
    "City of London",
    "City of York Council",
    "Clackmannanshire Council",
    "Colchester Borough Council",
    "Comhairle nan Eilean Siar",
    "Conwy County Borough Council",
    "Copeland Borough Council",
    "Cornwall Council",
    "Cotswold District Council",
    "Coventry City Council",
    "Craven District Council",
    "Crawley Borough Council",
    "Dacorum Council",
    "Darlington Borough Council",
    "Dartford Borough Council",
    "Dartmoor National Park",
    "Denbighshire County Council",
    "Derby City Council",
    "Derbyshire Dales District Council",
    "Derry City and Strabane District Council",
    "Doncaster Metropolitan Borough Council",
    "Dorset Council",
    "Dover District Council",
    "Dudley Metropolitan Borough Council",
    "Dumfries and Galloway Council",
    "Dundee City Council",
    "Durham County Council",
    "East Ayrshire Council",
    "East Cambridgeshire District Council",
    "East Devon District Council",
    "East Dunbartonshire Council",
    "East Hampshire District Council",
    "East Hertfordshire District Council",
    "East Lindsey District Council",
    "East Lothian Council",
    "East Renfrewshire Council",
    "East Riding of Yorkshire Council",
    "East Staffordshire Borough Council",
    "East Suffolk Council",
    "Eastbourne Borough Council",
    "Eastleigh Borough Council",
    "Ebbsfleet Development Corporation",
    "Eden District Council",
    "Elmbridge Borough Council",
    "Epping Forest District Council",
    "Epsom and Ewell Borough Council",
    "Erewash Borough Council",
    "Exeter City Council",
    "Exmoor National Park",
    "Falkirk Council",
    "Fareham Borough Council",
    "Fenland District Council",
    "Fermanagh and Omagh District Council",
    "Fife Council",
    "Flintshire County Council",
    "Folkestone & Hythe District Council",
    "Forest of Dean District Council",
    "Fylde Borough Council",
    "Gateshead Metropolitan Borough Council",
    "Gedling Borough Council",
    "Glasgow City Council",
    "Gloucester City Council",
    "Gosport Borough Council",
    "Gravesham Borough Council",
    "Great Yarmouth Borough Council",
    "Guildford Borough Council",
    "Gwynedd Council",
    "Halton Borough Council",
    "Hambleton District Council",
    "Harborough District Council",
    "Harlow Council",
    "Harrogate Borough Council",
    "Hart District Council",
    "Hartlepool Borough Council",
    "Hastings Borough Council",
    "Havant Borough Council",
    "Herefordshire Council",
    "Hertsmere Borough Council",
    "High Peak Borough Council",
    "Hinckley and Bosworth Borough Council",
    "Horsham District Council",
    "Huntingdonshire District Council",
    "Hyndburn Borough Council",
    "Inverclyde Council",
    "Ipswich Borough Council",
    "Isle of Anglesey County Council",
    "Isle of Wight Council",
    "Isles of Scilly",
    "King's Lynn and West Norfolk Borough Council",
    "Kingston-upon-Hull City Council",
    "Kirklees Council",
    "Knowsley Metropolitan Borough Council",
    "Lake District National Park",
    "Lancaster City Council",
    "Leeds City Council",
    "Leicester City Council",
    "Lewes District Council",
    "Lichfield District Council",
    "Lisburn and Castlereagh City Council",
    "Liverpool City Council",
    "London Borough of Barking and Dagenham",
    "London Borough of Barnet",
    "London Borough of Bexley",
    "London Borough of Brent",
    "London Borough of Bromley",
    "London Borough of Camden",
    "London Borough of Croydon",
    "London Borough of Ealing",
    "London Borough of Enfield",
    "London Borough of Hackney",
    "London Borough of Hammersmith & Fulham",
    "London Borough of Haringey",
    "London Borough of Harrow",
    "London Borough of Havering",
    "London Borough of Hillingdon",
    "London Borough of Hounslow",
    "London Borough of Islington",
    "London Borough of Lambeth",
    "London Borough of Lewisham",
    "London Borough of Merton",
    "London Borough of Newham",
    "London Borough of Redbridge",
    "London Borough of Richmond upon Thames",
    "London Borough of Southwark",
    "London Borough of Sutton",
    "London Borough of Tower Hamlets",
    "London Borough of Waltham Forest",
    "London Borough of Wandsworth",
    "London Legacy Development Corporation",
    "Luton Borough Council",
    "Maidstone Borough Council",
    "Maldon District Council",
    "Malvern Hills District Council",
    "Manchester City Council",
    "Mansfield District Council",
    "Medway Council",
    "Melton Borough Council",
    "Merthyr Tydfil County Borough Council",
    "Mid and East Antrim Borough Council",
    "Mid Devon District Council",
    "Mid Suffolk District Council",
    "Mid Sussex District Council",
    "Mid Ulster District Council",
    "Middlesbrough Borough Council",
    "Midlothian Council",
    "Milton Keynes City Council",
    "Mole Valley District Council",
    "Monmouthshire County Council",
    "Neath Port Talbot County Borough Council",
    "New Forest District Council",
    "New Forest National Park",
    "Newark and Sherwood District Council",
    "Newcastle-Under-Lyme District Council",
    "Newcastle-upon-Tyne City Council",
    "Newport City Council",
    "Newry, Mourne and Down District Council",
    "North Ayrshire Council",
    "North Devon Council",
    "North East Derbyshire District Council",
    "North East Lincolnshire Council",
    "North Hertfordshire District Council",
    "North Kesteven District Council",
    "North Lanarkshire Council",
    "North Lincolnshire Council",
    "North Norfolk District Council",
    "North Northamptonshire Council",
    "North Somerset Council",
    "North Tyneside Metropolitan Borough Council",
    "North Warwickshire Borough Council",
    "North West Leicestershire District Council",
    "North York Moors National Park",
    "North Yorkshire Council",
    "Northumberland Council",
    "Northumberland National Park",
    "Norwich City Council",
    "Nottingham City Council",
    "Nuneaton and Bedworth Borough Council",
    "Oadby and Wigston District Council",
    "Old Oak and Park Royal Development Corporation",
    "Oldham Metropolitan Borough Council",
    "Orkney Islands Council",
    "Oxford City Council",
    "Peak District National Park",
    "Pembrokeshire County Council",
    "Pendle Borough Council",
    "Perth and Kinross Council",
    "Peterborough City Council",
    "Plymouth City Council",
    "Portsmouth City Council",
    "Powys County Council",
    "Preston City Council",
    "Reading Borough Council",
    "Redcar and Cleveland Council",
    "Redditch Borough Council",
    "Reigate & Banstead Borough Council",
    "Renfrewshire Council",
    "Rhondda Cynon Taf County Borough Council",
    "Ribble Valley Borough Council",
    "Richmondshire District Council",
    "Rochdale Metropolitan Borough Council",
    "Rochford District Council",
    "Rossendale Borough Council",
    "Rother District Council",
    "Rotherham Metropolitan Borough Council",
    "Royal Borough of Greenwich",
    "Royal Borough of Kensington and Chelsea",
    "Royal Borough of Kingston upon Thames",
    "Royal Borough of Windsor and Maidenhead",
    "Rugby Borough Council",
    "Runnymede Borough Council",
    "Rushcliffe Borough Council",
    "Rushmoor Borough Council",
    "Rutland County Council",
    "Ryedale District Council",
    "Salford City Council",
    "Sandwell Metropolitan Borough Council",
    "Scarborough Borough Council",
    "Scottish Borders Council",
    "Sefton Metropolitan Borough Council",
    "Selby District Council",
    "Sevenoaks District Council",
    "Sheffield City Council",
    "Shetland Islands Council",
    "Shropshire Council",
    "Slough Borough Council",
    "Solihull Metropolitan Borough Council",
    "Somerset Council",
    "Somerset West and Taunton Council",
    "South Ayrshire Council",
    "South Cambridgeshire District Council",
    "South Derbyshire District Council",
    "South Downs National Park",
    "South Gloucestershire Council",
    "South Hams District Council",
    "South Holland District Council",
    "South Kesteven District Council",
    "South Lakeland District Council",
    "South Lanarkshire Council",
    "South Norfolk District Council",
    "South Oxfordshire District Council",
    "South Ribble Borough Council",
    "South Staffordshire Council",
    "South Tyneside Council",
    "Southampton City Council",
    "Southend-on-Sea Borough Council",
    "Spelthorne Borough Council",
    "St Albans City and District Council",
    "St Helens Metropolitan Borough Council",
    "Stafford Borough Council",
    "Staffordshire Moorlands District Council",
    "Stevenage Borough Council",
    "Stirling Council",
    "Stockport Metropolitan Borough Council",
    "Stockton-on-Tees Borough Council",
    "Stoke-on-Trent City Council",
    "Stratford-on-Avon District Council",
    "Stroud District Council",
    "Sunderland City Council",
    "Surrey Heath Borough Council",
    "Swale Borough Council",
    "Swindon Borough Council",
    "Tameside Metropolitan Borough Council",
    "Tamworth Borough Council",
    "Tandridge District Council",
    "Teignbridge District Council",
    "Telford & Wrekin Council",
    "Tendring District Council",
    "Test Valley Borough Council",
    "Tewkesbury Borough Council",
    "Thanet District Council",
    "The Highland Council",
    "The Moray Council",
    "The Vale of Glamorgan County Borough Council",
    "Three Rivers District Council",
    "Thurrock Council",
    "Tonbridge and Malling Borough Council",
    "Torbay Council",
    "Torfaen County Borough Council",
    "Torridge District Council",
    "Trafford Metropolitan Borough Council",
    "Tunbridge Wells Borough Council",
    "Uttlesford District Council",
    "Vale of White Horse District Council",
    "Wakefield Metropolitan District Council",
    "Walsall Metropolitan Borough Council",
    "Warrington Borough Council",
    "Warwick District Council",
    "Watford Borough Council",
    "Waverley Borough Council",
    "Wealden District Council",
    "Welwyn Hatfield Council",
    "West Berkshire Council",
    "West Devon Borough Council",
    "West Dunbartonshire Council",
    "West Lancashire Borough Council",
    "West Lindsey District Council",
    "West Lothian Council",
    "West Northamptonshire Council",
    "West Oxfordshire District Council",
    "West Suffolk Council",
    "Westminster City Council",
    "Wigan Metropolitan Borough Council",
    "Wiltshire Council",
    "Winchester City Council",
    "Wirral Council",
    "Woking Borough Council",
    "Wokingham Borough Council",
    "Wolverhampton City Council",
    "Worcester City Council",
    "Worthing Borough Council",
    "Wrexham County Borough Council",
    "Wychavon District Council",
    "Wyre Council",
    "Wyre Forest District Council",
    "Yorkshire Dales National Park",
];

export const typeOfPlanningApplicationOptions = [
    { label: "Full planning consent", value: "a full planning application" },
    { label: "Outline planning consent", value: "an outline planning application" },
    { label: "Hybrid planning consent", value: "a hybrid planning application" },
    { label: "Reserved matters", value: "a reserved matters application" },
    { label: "Removal/variation of conditions", value: "a removal / variation of conditions" },
    { label: "Approval (Discharge) of conditions", value: "an approval (discharge) of conditions" },
    {
        label: "Non-material amendment of an existing planning permission",
        value: "a non-material amendment of an existing planning application",
    },
];

export const cyclingWalkingMeasuresOptions = [
    "Cycling facilities e.g. changing facilities, showers",
    "On site safe cycling routes",
    "Financial contribution to connecting off site safe cycling routes",
    "On site safe pedestrian access routes",
    "Financial contribution to connecting off site safe pedestrian routes",
    "Additional cycling and walking signage / wayfinding",
    "Other",
];

export const publicTransportMeasuresOptions = [
    "Provision of bus stop",
    "Provision of lay-by",
    "Financial contribution to other public transport schemes",
    "Other",
];

export const carMeasuresOptions = [
    "None",
    "Limited hours of operation",
    "Parking management measures (e.g. priority parking with children, or multiple occupants)",
    "Dedicated disabled parking space(s)",
    "Additional road signage",
    "Other",
];

export const deliveryAccessMeasuresOptions = [
    "Hours of operation",
    "On site safe cycling routes",
    "Dedicated delivery area",
    "Lorry / van parking",
    "Turning spaces",
    "Other",
];

export const highwayImprovementsOptions = [
    "Visibility splays",
    "Junction widening",
    "New pedestrian crossing",
    "New cycling lanes",
    "Dropped kerbs",
    "Traffic signal upgrades",
    "Highway resurfacing",
    "New roundabout",
    "Bus stop improvements",
    "Pavement widening",
    "Traffic calming (e.g. speed bumps)",
    "Other",
];

export const parkingIsFreeOptions = ["Free", "Paid", "Both, options for different users", "No car parking"];

// Map keys and their respective components
export const mapComponents = {
    site_map: {
        component: {
            component: sitemapComponent,
            props: {
                suppressErrors: true,
            },
        },
        loadingText: "Generating site map...",
    },
    satellite_map: {
        component: {
            component: satelliteMapComponent,
            props: {
                suppressErrors: true,
            },
        },
        loadingText: "Generating satellite map...",
    },
    bus_stops_map: {
        component: {
            component: busServicesMapComponent,
            props: {
                suppressErrors: true,
            },
        },
        loadingText: "Generating bus stops map...",
    },
    rail_stations_map: {
        component: {
            component: railStationsMapComponent,
            props: {
                suppressErrors: true,
            },
        },
        loadingText: "Generating rail stations map...",
    },
    highways_map: {
        component: {
            component: highwaysMapComponent,
            props: {
                suppressErrors: true,
            },
        },
        loadingText: "Generating highways map...",
    },
    pedestrian_map: {
        component: {
            component: pedestrianMapComponent,
            props: {
                suppressErrors: true,
            },
        },
        loadingText: "Generating pedestrian access map...",
    },
    cycling_map: {
        component: {
            component: cyclingMapComponent,
            props: {
                suppressErrors: true,
            },
        },
        loadingText: "Generating cycling access map...",
    },
    driving_map: {
        component: {
            component: drivingMapComponent,
            props: {
                suppressErrors: true,
            },
        },
        loadingText: "Generating driving access map...",
    },
    amenities_map: {
        component: {
            component: amenitiesMapComponent,
            props: {
                mapKey: "amenities_map",
                amenities: uk_amenities_map_config,
                suppressErrors: true,
            },
        },
        loadingText: "Generating amenities map...",
    },
    collision_map: {
        component: {
            component: collisionMapComponent,
            props: {
                suppressErrors: true,
            },
        },
        loadingText: "Generating collision map...",
    },
    osm_bus_map: {
        component: {
            component: amenitiesMapComponent,
            props: {
                mapKey: "osm_bus_map",
                amenities: osm_bus_map_config,
                suppressErrors: true,
            },
        },
        loadingText: "Generating bus stops map...",
    },
    local_transport_map: {
        component: {
            component: amenitiesMapComponent,
            props: {
                mapKey: "local_transport_map",
                amenities: local_transport_map_config,
                suppressErrors: true,
            },
        },
        loadingText: "Generating local transport facilities map...",
    },
    us_amenities_map: {
        component: {
            component: amenitiesMapComponent,
            props: {
                mapKey: "us_amenities_map",
                amenities: us_amenities_map_config,
                suppressErrors: true,
            },
        },
        loadingText: "Generating local amenities map...",
    },
    ev_charger_map: {
        component: {
            component: amenitiesMapComponent,
            props: {
                mapKey: "ev_charger_map",
                amenities: ev_charger_map_config,
                suppressErrors: true,
            },
        },
        loadingText: "Generating EV charger map...",
    },
    community_facilities_map: {
        component: {
            component: amenitiesMapComponent,
            props: {
                mapKey: "community_facilities_map",
                amenities: community_facilities_map_config,
                suppressErrors: true,
            },
        },
        loadingText: "Generating community facilities map...",
    },
    ea_reservoir_flood_extents_map: {
        component: {
            component: amenitiesMapComponent,
            props: {
                mapKey: "ea_reservoir_flood_extents_map",
                amenities: ea_reservoir_flood_extents_map_config,
                suppressErrors: true,
            },
        },
        loadingText: "Generating Reservoir Flood Extents map...",
    },
    ea_flood_risk_zones_map: {
        component: {
            component: amenitiesMapComponent,
            props: {
                mapKey: "ea_flood_risk_zones_map",
                amenities: ea_flood_risk_zones_map_config,
                suppressErrors: true,
            },
        },
        loadingText: "Generating Flood Risk Zones map...",
    },
    ea_main_rivers_map: {
        component: {
            component: amenitiesMapComponent,
            props: {
                mapKey: "ea_main_rivers_map",
                amenities: ea_main_rivers_map_config,
                suppressErrors: true,
            },
        },
        loadingText: "Generating Main Rivers map...",
    },
    ea_surface_water_flood_risk_extents_map: {
        component: {
            component: amenitiesMapComponent,
            props: {
                mapKey: "ea_surface_water_flood_risk_extents_map",
                amenities: ea_surface_water_flood_risk_extents_map_config,
                suppressErrors: true,
            },
        },
        loadingText: "Generating Surface Water Flood Risk Extents map...",
    },
};

export const stateOptions = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
    "District of Columbia",
];

export const ukSiteUseOptions = {
    "Mixed use": { insertString: "mixed use" },
    Greenfield: { insertString: "greenfield land" },
    Brownfield: { insertString: "brownfield land" },
    Other: { insertString: "other" },
    "Boat Building": { code: "B2", insertString: "boat building" },
    "Car Repair Shop": { code: "B2", insertString: "car repair shop" },
    "Catering Premises & Production": { code: "B2", insertString: "catering premises & production" },
    Factory: { code: "B2", insertString: "factory" },
    "Film Studio": { code: "B2", insertString: "film studio" },
    "General Industrial": { code: "B2", insertString: "general industrial" },
    "Industrial Process": { code: "B2", insertString: "industrial process" },
    "Motorcycle Repair Shop": { code: "B2", insertString: "motorcycle repair shop" },
    "Movie Studio": { code: "B2", insertString: "movie studio" },
    "Yacht Building": { code: "B2", insertString: "yacht building" },
    Quarry: { code: "B2", insertString: "quarry" },
    "Civic Amenity Site": { code: "B2", insertString: "civic amenity site" },
    "Storage Building": { code: "B8", insertString: "storage building" },
    "Catering Storage & Distribution": { code: "B8", insertString: "catering storage & distribution" },
    "Distribution Centres": { code: "B8", insertString: "distribution centres" },
    "Open Air Storage": { code: "B8", insertString: "open air storage" },
    Warehouse: { code: "B8", insertString: "warehouse" },
    "Warehousing (Self Storage)": { code: "B8", insertString: "warehousing (self storage)" },
    "Parcel Distribution Centre": { code: "B8", insertString: "parcel distribution centre" },
    Aparthotel: { code: "C1", insertString: "aparthotel" },
    "Bed & Breakfast": { code: "C1", insertString: "bed & breakfast" },
    "Boarding House": { code: "C1", insertString: "boarding house" },
    "Guest House": { code: "C1", insertString: "guest house" },
    Hotel: { code: "C1", insertString: "hotel" },
    "Serviced Accommodation": { code: "C1 / C3 / Sui Generis", insertString: "serviced accommodation" },
    "Short Term Holiday Let": { code: "C1 / C3 / Sui Generis", insertString: "short term holiday let" },
    "Boarding School": { code: "C2", insertString: "boarding school" },
    "Residential School": { code: "C2", insertString: "residential school" },
    "Care Homes": { code: "C2", insertString: "care homes" },
    Hospital: { code: "C2", insertString: "hospital" },
    "General Hospital": { code: "C2", insertString: "general hospital" },
    "Special Hospital": { code: "C2", insertString: "special hospital" },
    "Private Hospital": { code: "C2", insertString: "private hospital" },
    "Teaching Hospital": { code: "C2", insertString: "teaching hospital" },
    "Nursing Home": { code: "C2", insertString: "nursing home" },
    "Residential Care Home": { code: "C2", insertString: "residential care home" },
    "Care Home": { code: "C2", insertString: "care home" },
    "Training Centre": { code: "C2", insertString: "training centre" },
    "Extra Care Housing": { code: "C2", insertString: "extra care housing" },
    Hospice: { code: "C2", insertString: "hospice" },
    "Custody Centre": { code: "C2A", insertString: "custody centre" },
    "Detention Centre": { code: "C2A", insertString: "detention centre" },
    "Military Barracks": { code: "C2A", insertString: "military barracks" },
    "Naval Barracks": { code: "C2A", insertString: "naval barracks" },
    Prison: { code: "C2A", insertString: "prison" },
    "Secure Hospital": { code: "C2A", insertString: "secure hospital" },
    "Secure Local Authority Accommodation": { code: "C2A", insertString: "secure local authority accommodation" },
    "Secure Training Centre": { code: "C2A", insertString: "secure training centre" },
    "Short Term Holding Centre": { code: "C2A", insertString: "short term holding centre" },
    "Young Offenders’ Institution": { code: "C2A", insertString: "young offenders’ institution" },
    Houses: { code: "C3(a)", insertString: "houses" },
    "Affordable Houses": { code: "C3(a)", insertString: "affordable houses" },
    "Local Authority Houses": { code: "C3(a)", insertString: "local authority houses" },
    Bungalow: { code: "C3(a)", insertString: "bungalow" },
    Apartments: { code: "C3(a)", insertString: "apartments" },
    Flats: { code: "C3(a)", insertString: "flats" },
    "Affordable Flats": { code: "C3(a)", insertString: "affordable flats" },
    "Local Authority Flats": { code: "C3(a)", insertString: "local authority flats" },
    Maisonettes: { code: "C3(a)", insertString: "maisonettes" },
    "Mixed Private Housing": { code: "C3(a)", insertString: "mixed private housing" },
    "Mixed Affordable Housing": { code: "C3(a)", insertString: "mixed affordable housing" },
    "Mixed Private / Affordable Housing": { code: "C3(a)", insertString: "mixed private / affordable housing" },
    "Retirement Flats": { code: "C3(a)", insertString: "retirement flats" },
    "Care Houses": { code: "C3(b)", insertString: "care houses" },
    "Supported Housing with Care": { code: "C3(b)", insertString: "supported housing with care" },
    "Assisted Living": { code: "C3(b)", insertString: "assisted living" },
    "Sheltered Housing": { code: "C3(b)", insertString: "sheltered housing" },
    "Sheltered Accommodation": { code: "C3(b)", insertString: "sheltered accommodation" },
    Bedsit: { code: "C4", insertString: "bedsit" },
    HMO: { code: "C4", insertString: "hmo" },
    "Barber Shop": { code: "E(a)", insertString: "barber shop" },
    Bookshop: { code: "E(a)", insertString: "bookshop" },
    "Builders Merchants": { code: "E(a)", insertString: "builders merchants" },
    Butcher: { code: "E(a)", insertString: "butcher" },
    "Car Service Shop": { code: "E(a)", insertString: "car service shop" },
    Chemist: { code: "E(a)", insertString: "chemist" },
    "Clothes Shop": { code: "E(a)", insertString: "clothes shop" },
    "Convenience Store": { code: "E(a)", insertString: "convenience store" },
    "Discount Food Store": { code: "E(a)", insertString: "discount food store" },
    "DIY Superstore": { code: "E(a)", insertString: "diy superstore" },
    "Dry Cleaners": { code: "E(a)", insertString: "dry cleaners" },
    "Factory Outlet Centre": { code: "E(a)", insertString: "factory outlet centre" },
    "Factory Shop": { code: "E(a)", insertString: "factory shop" },
    "Food Superstore": { code: "E(a)", insertString: "food superstore" },
    "Funeral Director": { code: "E(a)", insertString: "funeral director" },
    "Garden Centre": { code: "E(a)", insertString: "garden centre" },
    Greengrocer: { code: "E(a)", insertString: "greengrocer" },
    Hairdresser: { code: "E(a)", insertString: "hairdresser" },
    "Hire Shop": { code: "E(a)", insertString: "hire shop" },
    "Motorist DIY": { code: "E(a)", insertString: "motorist diy" },
    "Pet Shops": { code: "E(a)", insertString: "pet shops" },
    Pharmacy: { code: "E(a)", insertString: "pharmacy" },
    "Phone Shop": { code: "E(a)", insertString: "phone shop" },
    "Post Office": { code: "E(a)", insertString: "post office" },
    Printers: { code: "E(a)", insertString: "printers" },
    Retail: { code: "E(a)", insertString: "retail" },
    "Retail Park": { code: "E(a)", insertString: "retail park" },
    "Retail Warehouse": { code: "E(a)", insertString: "retail warehouse" },
    Saddlery: { code: "E(a)", insertString: "saddlery" },
    "Shopping Centre": { code: "E(a)", insertString: "shopping centre" },
    "Cold Food Shop": { code: "E(a)", insertString: "cold food shop" },
    "Sandwich Shop": { code: "E(a)", insertString: "sandwich shop" },
    "Shoe Shop": { code: "E(a)", insertString: "shoe shop" },
    Shop: { code: "E(a)", insertString: "shop" },
    "Non-Food Superstore": { code: "E(a)", insertString: "non-food superstore" },
    "Ticket Agency": { code: "E(a)", insertString: "ticket agency" },
    "Tourist Information": { code: "E(a)", insertString: "tourist information" },
    "Trade Shop": { code: "E(a)", insertString: "trade shop" },
    "Travel Agents": { code: "E(a)", insertString: "travel agents" },
    "Beauty Salon": { code: "E(a)", insertString: "beauty salon" },
    "Dog Grooming Parlour": { code: "E(a)", insertString: "dog grooming parlour" },
    "Massage Parlour": { code: "E(a)", insertString: "massage parlour" },
    "Mixed Bargain Retail Unit": { code: "E(a)", insertString: "mixed bargain retail unit" },
    "Nail Bar": { code: "E(a)", insertString: "nail bar" },
    "Sunbed Salon": { code: "E(a)", insertString: "sunbed salon" },
    "Tanning Salon": { code: "E(a)", insertString: "tanning salon" },
    "Tattoo Parlour": { code: "E(a)", insertString: "tattoo parlour" },
    "MOT Centre": { code: "E(a)", insertString: "mot centre" },
    Café: { code: "E(b)", insertString: "café" },
    "Internet Cafe": { code: "E(b)", insertString: "internet cafe" },
    Restaurant: { code: "E(b)", insertString: "restaurant" },
    "Road-Side Food": { code: "E(b)", insertString: "road-side food" },
    Bank: { code: "E(c)(i)", insertString: "bank" },
    "Building Society": { code: "E(c)(i)", insertString: "building society" },
    "Employment Agency": { code: "E(c)(i)", insertString: "employment agency" },
    "Estate Agency": { code: "E(c)(i)", insertString: "estate agency" },
    "Professional Services": { code: "E(c)(ii)", insertString: "professional services" },
    "Bowling Alley": { code: "E(c)(ii)", insertString: "bowling alley" },
    "Fitness Club": { code: "E(d)", insertString: "fitness club" },
    Gym: { code: "E(d)", insertString: "gym" },
    "Indoor Sport & Recreation": { code: "E(d)", insertString: "indoor sport & recreation" },
    "Leisure Centre": { code: "E(d)", insertString: "leisure centre" },
    "Leisure Park": { code: "E(d)", insertString: "leisure park" },
    "Play Centre": { code: "E(d)", insertString: "play centre" },
    "Snooker Hall": { code: "E(d)", insertString: "snooker hall" },
    "Tennis Club (Indoor)": { code: "E(d)", insertString: "tennis club (indoor)" },
    "Trampoline Park": { code: "E(d)", insertString: "trampoline park" },
    Clinic: { code: "E(e)", insertString: "clinic" },
    Dentist: { code: "E(e)", insertString: "dentist" },
    "Dental Surgery": { code: "E(e)", insertString: "dental surgery" },
    Doctor: { code: "E(e)", insertString: "doctor" },
    GP: { code: "E(e)", insertString: "gp" },
    "GP Surgery": { code: "E(e)", insertString: "gp surgery" },
    "Health Centre": { code: "E(e)", insertString: "health centre" },
    "NHS Walk-In Centre": { code: "E(e)", insertString: "nhs walk-in centre" },
    Osteopath: { code: "E(e)", insertString: "osteopath" },
    "Veterinary Practice (Vet)": { code: "E(e)", insertString: "veterinary practice (vet)" },
    "Vetinary Surgery": { code: "E(e)", insertString: "vetinary surgery" },
    Crèche: { code: "E(f)", insertString: "crèche" },
    "Day Nursery": { code: "E(f)", insertString: "day nursery" },
    Nursery: { code: "E(f)", insertString: "nursery" },
    "Day Centre": { code: "E(f)", insertString: "day centre" },
    "Artist Studio": { code: "E(g)(i)", insertString: "artist studio" },
    "Job Centre": { code: "E(g)(i)", insertString: "job centre" },
    "Music Studio": { code: "E(g)(i)", insertString: "music studio" },
    "Non-Retail Photographic Studio": { code: "E(g)(i)", insertString: "non-retail photographic studio" },
    Offices: { code: "E(g)(i)", insertString: "offices" },
    "Photographic Studio": { code: "E(g)(i)", insertString: "photographic studio" },
    "Police Office (not a station)": { code: "E(g)(i)", insertString: "police office (not a station)" },
    "Recording Studio": { code: "E(g)(i)", insertString: "recording studio" },
    Solicitors: { code: "E(g)(i)", insertString: "solicitors" },
    "Research & Development": { code: "E(g)(ii)", insertString: "research & development" },
    "DIY Shed": { code: "E(g)(iii)", insertString: "diy shed" },
    "Industrial Process ": { code: "E(g)(iii)", insertString: "industrial process " },
    "Light Industrial": { code: "E(g)(iii)", insertString: "light industrial" },
    College: { code: "F1(a)", insertString: "college" },
    "Education Facility": { code: "F1(a)", insertString: "education facility" },
    "Non-Residential Education and Training Centre": {
        code: "F1(a)",
        insertString: "non-residential education and training centre",
    },
    School: { code: "F1(a)", insertString: "school" },
    "Training Company": { code: "F1(a)", insertString: "training company" },
    University: { code: "F1(a)", insertString: "university" },
    "Primary School": { code: "F1(a)", insertString: "primary school" },
    "Secondary School": { code: "F1(a)", insertString: "secondary school" },
    "Community Education": { code: "F1(a)", insertString: "community education" },
    "Art Gallery": { code: "F1(b)", insertString: "art gallery" },
    Museum: { code: "F1(c)", insertString: "museum" },
    Library: { code: "F1(d)", insertString: "library" },
    "Public Library": { code: "F1(d)", insertString: "public library" },
    "Public Reading Room": { code: "F1(d)", insertString: "public reading room" },
    "Exhibition Hall": { code: "F1(e)", insertString: "exhibition hall" },
    "Exhibition Centre": { code: "F1(e)", insertString: "exhibition centre" },
    "Public Hall": { code: "F1(e)", insertString: "public hall" },
    "Church Hall": { code: "F1(f)", insertString: "church hall" },
    Church: { code: "F1(f)", insertString: "church" },
    Monastery: { code: "F1(f)", insertString: "monastery" },
    Mosque: { code: "F1(f)", insertString: "mosque" },
    "Place of Worship": { code: "F1(f)", insertString: "place of worship" },
    "Religious Instruction": { code: "F1(f)", insertString: "religious instruction" },
    Synagogue: { code: "F1(f)", insertString: "synagogue" },
    "Law Court": { code: "F1(g)", insertString: "law court" },
    "Local Shop": { code: "F2(a)", insertString: "local shop" },
    "Community Centre": { code: "F2(a)", insertString: "community centre" },
    "Meeting Hall": { code: "F2(b)", insertString: "meeting hall" },
    "Local Hall": { code: "F2(b)", insertString: "local hall" },
    "Scout Hut": { code: "F2(b)", insertString: "scout hut" },
    "Country Park": { code: "F2(c)", insertString: "country park" },
    "Cricket Pitch": { code: "F2(c)", insertString: "cricket pitch" },
    "Equestrian Centre ": { code: "F2(c)", insertString: "equestrian centre " },
    "Football Pitch": { code: "F2(c)", insertString: "football pitch" },
    "Football Pitch (5-a-Side)": { code: "F2(c)", insertString: "football pitch (5-a-side)" },
    "Golf Course": { code: "F2(c)", insertString: "golf course" },
    "Private 9 Hole Golf Course": { code: "F2(c)", insertString: "private 9 hole golf course" },
    "Private 18 Hole Golf Course": { code: "F2(c)", insertString: "private 18 hole golf course" },
    "Private >18 Hole Golf Course": { code: "F2(c)", insertString: "private >18 hole golf course" },
    "Municipal 18 Hole Golf Course": { code: "F2(c)", insertString: "municipal 18 hole golf course" },
    "Municipal >18 Hole Golf Course": { code: "F2(c)", insertString: "municipal >18 hole golf course" },
    "Driving Range": { code: "F2(c)", insertString: "driving range" },
    "3 Par Golf Course": { code: "F2(c)", insertString: "3 par golf course" },
    "Outdoor Sports": { code: "F2(c)", insertString: "outdoor sports" },
    "Petting Farm": { code: "F2(c)", insertString: "petting farm" },
    "Polo Ground": { code: "F2(c)", insertString: "polo ground" },
    "Sports Arena": { code: "F2(c)", insertString: "sports arena" },
    "Tennis Courts": { code: "F2(c)", insertString: "tennis courts" },
    "Tennis Club ": { code: "F2(c)", insertString: "tennis club " },
    "Watersports Centre": { code: "F2(c)", insertString: "watersports centre" },
    "Skating Rink": { code: "F2(d)", insertString: "skating rink" },
    "Ski Slope": { code: "F2(d)", insertString: "ski slope" },
    "Ice Rink": { code: "F2(d)", insertString: "ice rink" },
    "Swimming Pool": { code: "F2(d)", insertString: "swimming pool" },
    "Leisure Centre (Swimming Pool Dominant)": {
        code: "F2(d)",
        insertString: "leisure centre (swimming pool dominant)",
    },
    "Adult Entertainment Venue": { insertString: "adult entertainment venue" },
    "Agricultural Unit": { insertString: "agricultural unit" },
    "Alkali Work": { insertString: "alkali work" },
    "Amusement Arcade": { insertString: "amusement arcade" },
    "Amusement Centre": { insertString: "amusement centre" },
    Barn: { insertString: "barn" },
    "Betting Shop": { insertString: "betting shop" },
    "Bingo Hall": { insertString: "bingo hall" },
    "Boarding Kennel": { insertString: "boarding kennel" },
    "Cab Company": { insertString: "cab company" },
    "Car Hire": { insertString: "car hire" },
    "Car Hire Centre": { insertString: "car hire centre" },
    "Car Rental": { insertString: "car rental" },
    "Car Racing": { insertString: "car racing" },
    "Car Showroom": { insertString: "car showroom" },
    "Caravan SIte": { insertString: "caravan site" },
    "Cash & Carry": { insertString: "cash & carry" },
    Casino: { insertString: "casino" },
    Cattery: { insertString: "cattery" },
    Cinema: { insertString: "cinema" },
    "Concert Hall": { insertString: "concert hall" },
    "Dairy Farm": { insertString: "dairy farm" },
    "Dance Hall": { insertString: "dance hall" },
    "Drinking Establishments with Expanded Food Provision": {
        insertString: "drinking establishments with expanded food provision",
    },
    "Electric Vehicle Charging Station": { insertString: "electric vehicle charging station" },
    Equestrian: { insertString: "equestrian" },
    Farm: { insertString: "farm" },
    "Fast Food Takeaway": { insertString: "fast food takeaway" },
    "Firearms Sports": { insertString: "firearms sports" },
    "Fruit Growing": { insertString: "fruit growing" },
    "Fuel Station": { insertString: "fuel station" },
    Funfair: { insertString: "funfair" },
    "Garage (Fuel)": { insertString: "garage (fuel)" },
    "Haulage Depot": { insertString: "haulage depot" },
    "HMOs (7+ people)": { insertString: "hmos (7+ people)" },
    Horticulture: { insertString: "horticulture" },
    Hostel: { insertString: "hostel" },
    "Hot Food Takeaways": { insertString: "hot food takeaways" },
    "Institutional Hostel": { insertString: "institutional hostel" },
    Kennels: { insertString: "kennels" },
    "Landfill Site": { insertString: "landfill site" },
    "Lap Dancing Club": { insertString: "lap dancing club" },
    "Large HMOs": { insertString: "large hmos" },
    Launderette: { insertString: "launderette" },
    "Live Music Venue": { insertString: "live music venue" },
    "Lorry Park": { insertString: "lorry park" },
    "Market Gardens": { insertString: "market gardens" },
    "Minicab Company": { insertString: "minicab company" },
    "Motorcycle Showroom": { insertString: "motorcycle showroom" },
    "Motorsport Arena": { insertString: "motorsport arena" },
    "Multiplex Cinema": { insertString: "multiplex cinema" },
    "Mushroom Farm": { insertString: "mushroom farm" },
    Nightclub: { insertString: "nightclub" },
    "Nursery Ground": { insertString: "nursery ground" },
    "Other Drinking Establishment": { insertString: "other drinking establishment" },
    "Payday Loan Shop": { insertString: "payday loan shop" },
    "Petrol Station": { insertString: "petrol station" },
    "Petrol Filling Station": { insertString: "petrol filling station" },
    "Petrol Filling Station with Retail": { insertString: "petrol filling station with retail" },
    "Police Station": { insertString: "police station" },
    "Postal Sorting Office": { insertString: "postal sorting office" },
    "Private Hire Company": { insertString: "private hire company" },
    Pub: { insertString: "pub" },
    "Pub / Restaurant": { insertString: "pub / restaurant" },
    "Public House": { insertString: "public house" },
    "Retail Warehouse Club": { insertString: "retail warehouse club" },
    "Scrap Yard": { insertString: "scrap yard" },
    "Student Block": { insertString: "student block" },
    Takeaway: { insertString: "takeaway" },
    "Takeaway Shop": { insertString: "takeaway shop" },
    "Taxi Company": { insertString: "taxi company" },
    Theatre: { insertString: "theatre" },
    "Vehicle Sport": { insertString: "vehicle sport" },
    "Visitor Centre": { insertString: "visitor centre" },
    "Wine Bar": { insertString: "wine bar" },
    "Yard for Breaking of Motor Vehicles": { insertString: "yard for breaking of motor vehicles" },
    "Yard for Storage / Distribution of Minerals": {
        insertString: "yard for storage / distribution of minerals",
    },
    Zoo: { insertString: "zoo" },
    "Builders Yard": { insertString: "builders yard" },
    "Hazardous Waste Disposal": { insertString: "hazardous waste disposal" },
    "Chemical Treatment": { insertString: "chemical treatment" },
    "Waste Disposal": { insertString: "waste disposal" },
    "Waste Incinerator": { insertString: "waste incinerator" },
    Vacant: { insertString: "vacant" },
    Derelict: { insertString: "derelict" },
    Agricultural: { insertString: "agricultural" },
};

export const usSiteUseOptions = {
    "Multifamily Housing": { insertString: "multifamily housing" },
    "Derelict / vacant": { insertString: "vacant" },
    Houses: { insertString: "residential houses" },
    Industry: { insertString: "industrial" },
    "Mixed use": { insertString: "mixed use" },
    Offices: { insertString: "offices" },
    "Retail and service": { insertString: "retail" },
    "Storage and Distribution": { insertString: "storage and distribution" },
    Greenfield: { insertString: "greenfield land" },
    Brownfield: { insertString: "brownfield land" },
    Other: { insertString: "other" },
};

export const siteUseOptionsConfigMap = {
    uk: ukSiteUseOptions,
    usa: usSiteUseOptions,
};

export const externalToolboxModules = [
    {
        title: "TfL WebCAT (PTAL)",
        icon: TflWebcatPTALIcon,
        link: "https://tfl.gov.uk/info-for/urban-planning-and-construction/planning-with-webcat/webcat",
        name: "tfl_webcat_ptal",
    },
    {
        title: "National Highways Counts",
        icon: NationalHighwaysCountsIcon,
        link: "https://webtris.highwaysengland.co.uk/",
        name: "nat_highway_counts",
    },
    {
        title: "National Cycle Network",
        icon: NationalCycleNetworkIcon,
        link: "https://explore.osmaps.com/?lat={{latitude}}&lon={{longitude}}&zoom=14&style=Standard&type=2d&overlays=os-ncn-layer",
        name: "nat_cycle_network",
    },
    {
        title: "Bike Share Schemes",
        icon: BikeShareSchemesIcon,
        link: "https://bikesharemap.com/#/8/{{longitude}}/{{latitude}}/",
        name: "bike_share_schemes",
    },
    {
        title: "EV Charging Points",
        icon: EVChargingPointsIcon,
        link: "https://www.zap-map.com/live/",
        name: "ev_charging_points",
    },
    {
        title: "Rail Station Usage",
        icon: RailStationUsageIcon,
        link: "https://dataportal.orr.gov.uk/statistics/usage/estimates-of-station-usage",
        name: "rail_station_usage",
    },
    {
        title: "Google Maps",
        icon: GoogleMapsIcon,
        link: "https://www.google.com/maps/@?api=1&map_action=map&center={{latitude}}%2C{{longitude}}&layer=none",
        name: "google_maps",
    },
    {
        title: "Google Street View",
        icon: StreetviewIcon,
        link: "https://www.google.com/maps/@?api=1&map_action=pano&viewpoint={{latitude}}%2C{{longitude}}",
        name: "google_streetview",
    },
    {
        title: "Google Maps Traffic View",
        icon: TrafficIcon,
        link: "https://www.google.com/maps/@?api=1&map_action=map&center={{latitude}}%2C{{longitude}}&layer=traffic",
        name: "google_maps_traffic",
    },
    {
        title: "Enterprise Car Club Locations",
        icon: CarClubIcon,
        link: "https://www.enterprisecarclub.co.uk/gb/en/programs/promotion/enterprise-car-club-map1.html",
        name: "enterprise_car_club",
    },
    {
        title: "ZipCar Locations",
        icon: CarClubIcon,
        link: "https://my.zipcar.com/search",
        name: "zipcar",
    },
    {
        title: "Co-Wheels Locations",
        icon: CarClubIcon,
        link: "https://www.co-wheels.org.uk/find-car",
        name: "co_wheels",
    },
    {
        title: "EV Charging Points (Zapmap)",
        icon: EVChargingPointsIcon,
        link: "https://www.zap-map.com/live/",
        name: "ev_charging_points_zapmap",
    },
    {
        title: "Offsite Parking (Parkopedia)",
        icon: OffsiteParkingIcon,
        link: "https://www.parkopedia.com/",
        name: "parkopedia",
    },
    {
        title: "Light Rail Usage (DfT)",
        icon: LightRailIcon,
        link: "https://www.gov.uk/government/statistical-data-sets/light-rail-and-tram-statistics-lrt##passenger-journeys-vehicle-miles-vehicle-kilometres-and-occupancy-lrt01",
        name: "light_rail_usage_dft",
    },
    {
        title: "Census Travel to Work Flows (ONS/Datashine, 2011)",
        icon: TravelToWorkFlowsIcon,
        link: "https://commute.datashine.org.uk/#mode=allflows&direction=both&msoa=E02000378&zoom=11.2&lon={{longitude}}&lat={{latitude}}",
        name: "census_travel_to_work_flows",
    },
    {
        title: "Census Travel to Work Methods (ONS, 2021)",
        icon: TravelToWorkMethodsIcon,
        link: "https://www.ons.gov.uk/census/maps/choropleth/work/method-of-travel-to-workplace/transport-to-workplace-12a/work-mainly-at-or-from-home/",
        name: "census_travel_to_work_methods",
    },
    {
        title: "Census Travel to Work Methods (ONS/Datashine, 2011)",
        icon: TravelToWorkMethodsIcon,
        link: "https://datashine.org.uk/#table=QS701EW&col=QS701EW0011&ramp=YlOrRd&layers=BTTT&zoom=12&lon={{longitude}}&lat={{latitude}}",
        name: "census_travel_to_work_methods_datashine",
    },
    {
        title: "Census Car Ownership (ONS, 2021)",
        icon: CarOwnershipIcon,
        link: "https://www.ons.gov.uk/census/maps/choropleth/housing/number-of-cars-or-vans/number-of-cars-3a/no-cars-or-vans-in-household",
        name: "census_car_ownership",
    },
    {
        title: "Census Distance Travelled to Work (ONS, 2021)",
        icon: DistanceTravelledToWorkIcon,
        link: "https://www.ons.gov.uk/census/maps/choropleth/work/distance-travelled-to-work/workplace-travel-4a/less-than-10km",
        name: "census_distance_travelled_to_work",
    },
    {
        title: "TRICS",
        icon: TRICsIcon,
        link: "https://trics.co.uk",
        name: "trics",
    },
    {
        title: "NPPF - Dec 2024",
        icon: PolicyIcon,
        link: "https://www.gov.uk/government/publications/national-planning-policy-framework--2",
        name: "nppf_dec_2024",
    },
    {
        title: "NPPG - Mar 2014",
        icon: PolicyIcon,
        link: "https://www.gov.uk/guidance/travel-plans-transport-assessments-and-statements",
        name: "nppg_mar_2014",
    },
    {
        title: "DEFRA Survey Data",
        icon: SiteLocationMapIcon,
        link: "https://environment.data.gov.uk/survey",
        name: "defra_survey_data",
    },
    {
        title: "EA Main River Map",
        icon: SiteLocationMapIcon,
        link: "https://www.arcgis.com/apps/mapviewer/index.html?layers=781494c6b6ce4a6bb8147341f99c2daf",
        name: "ea_main_river_map",
    },
    {
        title: "BGS Geology Viewer",
        icon: SiteLocationMapIcon,
        link: "https://geologyviewer.bgs.ac.uk/",
        name: "bgs_geology_viewer",
    },
    {
        title: "EA FRA Standing Advice",
        icon: PolicyIcon,
        link: "https://www.gov.uk/guidance/flood-risk-assessment-standing-advice",
        name: "ea_fra_standing_advice",
    },
    {
        title: "EA Flood Map",
        icon: SiteLocationMapIcon,
        link: "https://flood-map-for-planning.service.gov.uk/location",
        name: "ea_flood_map",
    },
    {
        title: "EA Surface Water Map",
        icon: SiteLocationMapIcon,
        link: "https://check-long-term-flood-risk.service.gov.uk/map?map=SurfaceWater#",
        name: "ea_surface_water_map",
    },
    {
        title: "EA Reservoirs Map",
        icon: SiteLocationMapIcon,
        link: "https://check-long-term-flood-risk.service.gov.uk/map?map=Reservoirs",
        name: "ea_reservoir_map",
    },
    {
        title: "Caltrans: VMT-Focused Transportation Impact Study Guide",
        icon: PolicyIcon,
        link: "https://dot.ca.gov/-/media/dot-media/programs/transportation-planning/documents/sb-743/2020-05-20-approved-vmt-focused-tisg-a11y.pdf",
        name: "caltrans_vmt_tisg",
    },
    {
        title: "SANDAG: Traffic Generation Rates for the San Diego Region",
        icon: PolicyIcon,
        link: "https://www.sandiegocounty.gov/content/dam/sdc/pds/ceqa/LehmanTPM/38%20Appendix%20T9_SANDAG%20Trip%20Generation%20Rates.pdf",
        name: "sandag_trip_gen",
    },
    {
        title: "SANDAG VMT Map",
        icon: PolicyIcon,
        link: "https://sandag.maps.arcgis.com/apps/webappviewer/index.html?id=bb8f938b625c40cea14c825835519a2b",
        name: "sandag_vmt_map",
    },
    {
        title: "City of San Diego Trip Generation Manual",
        icon: PolicyIcon,
        link: "https://www.sandiego.gov/sites/default/files/appendix-m-trip-generation-manual_0.pdf",
        name: "sd_city_trip_gen",
    },
    {
        title: "City of San Diego Transportation Study Manual (TSM)",
        icon: PolicyIcon,
        link: "https://www.sandiego.gov/sites/default/files/10-transportation-study-manual.pdf",
        name: "sd_city_tsm",
    },
    {
        title: "County of San Diego Transportation Study Guidelines",
        icon: PolicyIcon,
        link: "https://www.sandiegocounty.gov/content/dam/sdc/pds/SB743/Transportation%20Study%20Guide%20-%20FINAL%20-%20September%202022.pdf",
        name: "sd_county_guidelines",
    },
    {
        title: "ITE Trip Generation",
        icon: PolicyIcon,
        link: "https://www.itetripgen.org/",
        name: "ite_trip_gen_external",
    },
];

export const schemeflowToolboxModules = [
    {
        title: "Site Location Map",
        icon: SiteLocationMapIcon,
        to: "site_map",
        name: "site_map",
        browserOnly: false,
    },
    {
        title: "Amenities Map",
        icon: AmenitiesMapIcon,
        to: "us_amenities_map",
        name: "us_amenities_map",
        browserOnly: false,
    },
    {
        title: "Amenities Map",
        icon: AmenitiesMapIcon,
        to: "amenities_map",
        name: "amenities_map",
        browserOnly: false,
    },
    {
        title: "Community Facilities Map",
        icon: CommunityFacilitiesMapIcon,
        to: "community_facilities_map",
        name: "community_facilities_map",
        browserOnly: false,
    },
    {
        title: "Bus Services",
        icon: BusServicesIcon,
        to: "bus_services",
        name: "bus_services",
        browserOnly: false,
    },
    {
        title: "Bus Stops",
        icon: BusServicesIcon,
        to: "osm_bus_map",
        name: "osm_bus_map",
        browserOnly: false,
    },
    {
        title: "Rail Services",
        icon: RailServicesIcon,
        to: "rail_services",
        name: "rail_services",
        browserOnly: false,
    },
    {
        title: "Local Transport Facilities Map",
        icon: RailServicesIcon,
        to: "local_transport_map",
        name: "local_transport_map",
        browserOnly: false,
    },
    {
        title: "Walking Isochrone",
        icon: WalkingIsochroneIcon,
        to: "pedestrian_map",
        name: "pedestrian_map",
        browserOnly: false,
    },
    {
        title: "Satellite Map",
        icon: SatelliteMapIcon,
        to: "satellite_map",
        name: "satellite_map",
        browserOnly: false,
    },
    {
        title: "Cycling Isochrone",
        icon: CyclingIsochroneIcon,
        to: "cycling_map",
        name: "cycling_map",
        browserOnly: false,
    },
    {
        title: "Local Highways Map",
        icon: HighwaysMapIcon,
        to: "highways_map",
        name: "highways_map",
        browserOnly: false,
    },
    {
        title: "Driving Isochrone",
        icon: DrivingIsochroneIcon,
        to: "driving_map",
        name: "driving_map",
        browserOnly: false,
    },
    {
        title: "Collision Map",
        icon: CollisionMapIcon,
        to: "collision_map",
        name: "collision_map",
        browserOnly: false,
    },
    {
        title: "EV Charger Map",
        icon: ChargerIcon,
        to: "ev_charger_map",
        name: "ev_charger_map",
        browserOnly: false,
    },
    {
        title: "Main Rivers Map",
        icon: FloodRiskMapIcon,
        to: "ea_main_rivers_map",
        name: "ea_main_rivers_map",
        browserOnly: false,
    },
    {
        title: "Flood Risk Zones Map",
        icon: FloodRiskMapIcon,
        to: "ea_flood_risk_zones_map",
        name: "ea_flood_risk_zones_map",
        browserOnly: false,
    },
    {
        title: "Reservoir Flood Extents Map",
        icon: FloodRiskMapIcon,
        to: "ea_reservoir_flood_extents_map",
        name: "ea_reservoir_flood_extents_map",
        browserOnly: false,
    },
    {
        title: "Surface Water Flood Risk Extents Map",
        icon: FloodRiskMapIcon,
        to: "ea_surface_water_flood_risk_extents_map",
        name: "ea_surface_water_flood_risk_extents_map",
        browserOnly: false,
    },
    {
        title: "Local Policy Explorer",
        icon: PolicyIcon,
        to: "policy",
        name: "policy",
        browserOnly: false,
    },
    {
        title: "Parking Standards",
        icon: ParkingStandardsIcon,
        to: "parking_standards",
        name: "parking_standards",
        browserOnly: false,
    },
];

export const stripeKeys = {
    UK: import.meta.env.VITE_STRIPE_API_KEY_UK,
    USA: import.meta.env.VITE_STRIPE_API_KEY_USA,
};

export const snippetReportChapterOptions = [
    "Contents",
    "Introduction",
    "Existing Conditions",
    "Proposed Development",
    "Trip Generation",
    "Future Conditions",
    "Parking",
    "Conclusions",
];

export const snippetClassOptions = [
    "Data Summary",
    "Introduction",
    "Legal / Disclaimer",
    "Methodology",
    "Overview",
    "Standards / Regulation",
];
