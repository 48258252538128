import { formatInTimeZone } from "date-fns-tz";
import { enGB } from "date-fns/locale/en-GB";
import { enUS } from "date-fns/locale/en-US";
import { useUserStore } from "@/stores/UserStore";

/**
 * Fetches a resource from the given URL and converts it to a Base64-encoded string.
 *
 * @param {string} url - The URL of the resource to be fetched.
 * @returns {Promise<string>} A promise that resolves to a Base64-encoded string representation of the fetched resource.
 * @throws {Error} If an error occurs while fetching the resource or converting it to Base64.
 */
export async function getBase64StringFromUrl(url) {
    var res = await fetch(url);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.addEventListener(
            "load",
            function () {
                resolve(reader.result);
            },
            false
        );

        reader.onerror = () => {
            return reject(this);
        };
        reader.readAsDataURL(blob);
    });
}

const dateFnsLocales = {
    "en-GB": enGB,
    "en-US": enUS,
    default: enGB,
};

export function formatTimeAsLocalTime(timeString) {
    const userStore = useUserStore();

    const date = new Date(timeString);
    const localTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const locale = navigator.language;

    const dateFnsLocale = dateFnsLocales[locale] ?? dateFnsLocales["default"];

    const formattedTimeString = formatInTimeZone(date, localTZ, userStore.thisRegionConfig.timeFormat, {
        locale: dateFnsLocale,
    });

    return formattedTimeString;
}
