import siteMapPlaceholder from "@/assets/img/cc_placeholders/SiteLocationMap.png.base64.txt?raw";
import satelliteMapPlaceholder from "@/assets/img/cc_placeholders/SatelliteMap.png.base64.txt?raw";
import highwaysMapPlaceholder from "@/assets/img/cc_placeholders/LocalHighwaysMap.png.base64.txt?raw";
import busStopsMapPlaceholder from "@/assets/img/cc_placeholders/BusStopsMap.png.base64.txt?raw";
import railStationsMapPlaceholder from "@/assets/img/cc_placeholders/RailStationsMap.png.base64.txt?raw";
import amenitiesMapPlaceholder from "@/assets/img/cc_placeholders/AmenitiesMap.png.base64.txt?raw";
import pedestrianMapPlaceholder from "@/assets/img/cc_placeholders/PedestrianIsochrone.png.base64.txt?raw";
import cyclingMapPlaceholder from "@/assets/img/cc_placeholders/CyclingIsochrone.png.base64.txt?raw";
import drivingMapPlaceholder from "@/assets/img/cc_placeholders/DrivingIsochrone.png.base64.txt?raw";
import accidentMapPlaceholder from "@/assets/img/cc_placeholders/AccidentMap.png.base64.txt?raw";

import axios from "axios";
import axiosRetry from "axios-retry";

const axiosInstance = axios.create();
axiosRetry(axiosInstance, { retryDelay: axiosRetry.exponentialDelay });

export const fetchAllContentControlConfig = async () => {
    try {
        const response = await axiosInstance.get("/api/config/contentControlConfig");
        return response.data;
    } catch (error) {
        console.error("Error fetching content control config: ", error);
    }
};

export const fetchContentControlConfigByKey = async (key) => {
    try {
        const response = await axiosInstance.get(`/api/config/contentControlConfig/${key}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching content control config: ", error);
    }
};

export const contentControlPrefixes = {
    TOOLBOX: "sftb_",
    DOCUMENT_PROPERTY: "sfpp_",
    MAP: "sfmp_",
    SUMMARY: "sfsm_",
};

// takes a tag like "sfmp_site_map" and returns an array, [prefix, tagName],
// such as ["sfmp_", "site_map"]. The tagName can be used as the key to
// contentControlConfig to get other cc properties.
export function getContentControlPrefixName(tag) {
    return [tag.slice(0, 5), tag.slice(5)];
}

export const placeholderMaps = {
    site_map: siteMapPlaceholder,
    satellite_map: satelliteMapPlaceholder,
    highways_map: highwaysMapPlaceholder,
    bus_stops_map: busStopsMapPlaceholder,
    rail_stations_map: railStationsMapPlaceholder,
    amenities_map: amenitiesMapPlaceholder,
    pedestrian_map: pedestrianMapPlaceholder,
    cycling_map: cyclingMapPlaceholder,
    driving_map: drivingMapPlaceholder,
    accident_map: accidentMapPlaceholder,
};

export const SF_XML_NAMESPACE = "http://report.schemeflow.com/v1";

// Maps module names to the map content control associated with that module. For
// many, the content control key is the same as the module name, so these could
// be looked up using fetchContentControlConfigByKey. But some, such as bus and
// rail services, have a different name for the module from the content control
// key (e.g. `bus_services` vs `bus_stops_map`)
export const mapModuleContentControls = {
    site_map: "Site Location Map",
    satellite_map: "Satellite Map",
    bus_services: "Bus Stops Map",
    highways_map: "Local Highways Map",
    rail_services: "Rail Stations Map",
    pedestrian_map: "Walking Isochrone",
    cycling_map: "Cycling Isochrone",
    driving_map: "Driving Isochrone",
    amenities_map: "Amenities Map",
    osm_bus_map: "Bus Map",
    local_transport_map: "Local Transport Facilities map",
    us_amenities_map: "Amenities Map",
    ev_charger_map: "Electric Vehicle Charger Map",
    community_facilities_map: "Community Facilities Map",
    collision_map: "Collision Map",
    ea_reservoir_flood_extents_map: "EA Reservoir Flood Extents Map",
    ea_flood_risk_zones_map: "EA Flood Risk Zones Map",
    ea_main_rivers_map: "EA Main Rivers Map",
    ea_surface_water_flood_risk_extents_map: "EA Surface Water Flood Risk Extents Map",
};
