import { inject, ref, computed } from "vue";
import { defineStore } from "pinia";
import axios from "axios";
import ukConfig from "@/config/regions/uk.json";
import usaConfig from "@/config/regions/usa.json";
import * as Sentry from "@sentry/vue";
import { externalToolboxModules, schemeflowToolboxModules } from "@/constants";
import { regions } from "@/config/config";

export const useUserStore = defineStore("User", () => {
    const backendUserConfig = ref({});

    const axiosInstance = inject("axios");
    const posthog = inject("posthog");
    const runningInOffice = inject("appIsRunningInOffice");

    const userThinksTheyAreLoggedIn = ref(null);

    const thisRegionConfig = ref(ukConfig);

    const checkIfUserIsLoggedIn = async () => {
        // If user store does not know login state, call API (/users/me) to check
        if (userThinksTheyAreLoggedIn.value == null) {
            try {
                // Call API to check
                // use original axios (without interceptor) so we don't bounce back to login screen
                let resp = await axios.get("/api/users/me");
                backendUserConfig.value = resp.data;
                userThinksTheyAreLoggedIn.value = true;
                let domain = resp.data.email.split("@")[1];
                posthog.identify(resp.data.email, {
                    domain: domain,
                    runningInOffice: runningInOffice.value,
                });

                // Set sentry user
                Sentry.setUser({
                    email: resp.data.email,
                });

                // Set region config
                switch (resp.data.region) {
                    case regions.UK:
                        thisRegionConfig.value = ukConfig;
                        break;
                    case regions.USA:
                        thisRegionConfig.value = usaConfig;
                        break;
                    default:
                        thisRegionConfig.value = ukConfig;
                }
            } catch (e) {
                userThinksTheyAreLoggedIn.value = false;
            }
        }
        return userThinksTheyAreLoggedIn.value;
    };

    async function logout() {
        const apiUrl = "/api/sessionLogout";
        userThinksTheyAreLoggedIn.value = null;

        await axiosInstance.post(apiUrl);

        // Reset posthog user
        posthog.reset();

        return;
    }

    const moduleAvailable = (routeName) => {
        // Module is available if all modules or the specific module are
        // available
        return backendUserConfig.value.module_access?.[routeName];
    };

    const resourceAvailable = (resource) => {
        // Resource is available if all resources or the specific resource are
        // available
        return backendUserConfig.value.resource_access?.[resource];
    };

    const schemeflowModules = computed(() => {
        // If module access does not exist, return empty list
        if (!backendUserConfig.value.module_access) {
            return [];
        }

        // Filter list based on available modules
        return schemeflowToolboxModules.filter((tlbx) => backendUserConfig.value.module_access?.[tlbx.name]);
    });

    // Schemeflow modules (that the user has permission to access) + ALL external modules
    const allModules = computed(() => {
        return [
            ...schemeflowToolboxModules.filter((module) => backendUserConfig.value.module_access?.[module.name]),
            ...externalToolboxModules,
        ];
    });

    return {
        logout,
        userThinksTheyAreLoggedIn,
        checkIfUserIsLoggedIn,
        thisRegionConfig,
        backendUserConfig,
        moduleAvailable,
        resourceAvailable,
        schemeflowModules,
        allModules,
    };
});
